import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./features/auth/ProtectedRoute";
import { I18nextProvider } from "react-i18next";
import i18n from "./translation";
import { fetchUserInfoAction } from "./features/userInfo/userInfoSlice";
import { useEffect } from "react";
import TokenService from "./features/auth/tokenService";
import { useAppDispatch } from "./app/hooks";

import Search from "./features/search/Search";
import Bulk from "./features/address/Bulk";
import D4Weather from "./features/data4weather/D4Weather";
import RiskEditor from "./features/riskEditor/RiskEditor";
import Auth from "./features/auth/Auth";

import "./App.css";

export default function App() {
  const dispatch = useAppDispatch();
  useEffect(function() {
    const accessToken = TokenService.getLocalAccessToken();
    if (accessToken) {
      dispatch(fetchUserInfoAction(accessToken));
    }
  }, [dispatch]);

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Search />
              </ProtectedRoute>
            }
          />
          <Route
            path="/address/bulk"
            element={
              <ProtectedRoute>
                <Bulk />
              </ProtectedRoute>
            }
          />
          <Route
            path="/weather"
            element={
              <ProtectedRoute>
                <D4Weather />
              </ProtectedRoute>
            }
          />
          <Route
            path="/risk-editor"
            element={
              <ProtectedRoute>
                <RiskEditor />
              </ProtectedRoute>
            }
          />
          <Route path="/auth/*" element={<Auth />} />
        </Routes>
      </Router>
    </I18nextProvider>
  );
}
