import { useState, ChangeEvent, FormEvent, Fragment, useEffect, useMemo } from 'react';
import { uploadEventfile, getEvents } from '../address/data4homeAPI'
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import DataGridComponent from "../../components/DataGridComponent/DataGridComponent";
import { GridColDef } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import SearchResultBlock from "../../components/Block";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

// TODO: Edit on event, delete events and remove edit form from search page
// TODO: use redux for fetching event and add loader

export default function EventEditor() {
    const [file, setFile] = useState<File | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [uploadFailedEvents, setUploadFailedEvents] = useState<any[]>([]);
    const [events, setEvents] = useState<any[]>([]);
    const [eventCount, setEventCount] = useState<number>(0);
    const { t } = useTranslation();

    const itemsPerPage = 20;

    // TODO: use redux saga
    const fetchEvents = useMemo(() => {
        return (page: number) => {
            getEvents({resultPerPage: itemsPerPage, page: page })
            .then(data => {
                setEvents(data.result)
                setEventCount(data.totalCount)
            })
            .catch((error) => {
                enqueueSnackbar(
                    "L'interrogation du serveur a échoué",
                    { variant: "error" }
                );
            });
        }
    }, [enqueueSnackbar])

    useEffect(function() {
        fetchEvents(1);
    }, [enqueueSnackbar, fetchEvents]);

    const handleEventFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    };

    const handleEventFileSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (!file) {
            alert('Veuillez sélectionner un fichier à télécharger.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        uploadEventfile(formData)
        .then(data => {
            setUploadFailedEvents(data.failed.map((item: any, index: number) => {
                return {
                    id: index,
                    ...item.event,
                    reason: item.reason
                }
            }));
            enqueueSnackbar(
                "Le fichier a été traité avec succès",
                { variant: "success" }
            );
            fetchEvents(1);
        })
        .catch((error) => {
            let message = "Le traitement du fichier a échoué"
            if (error.response) {
                message = error.response.data.error
            }
            enqueueSnackbar(
                message,
                { variant: "error" }
            );
        })
        .finally(() => {
            setFile(null)
        });
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        fetchEvents(value);
    };

    const columns: GridColDef[] = [
        {
            field: "insee_code",
            headerName: t("inseeCode"),
            flex: 1,
            minWidth: 80,
            sortable: false,
        },
        {
            field: "start_date",
            headerName: t("startDate"),
            flex: 1,
            minWidth: 80,
            sortable: false,
        },
        {
            field: "end_date",
            headerName: t("endDate"),
            flex: 1,
            minWidth: 80,
            sortable: false,
        },
        {
          field: "content_type",
          headerName: t("contentType"),
          flex: 1,
          sortable: false,
          renderCell: (params: any) => (
            <div style={{textTransform: "capitalize"}}>
              {t(`${params?.row.content_type}`)}
            </div>
          )
        },
        {
          field: "name",
          headerName: t("name"),
          flex: 2,
          minWidth: 80,
          sortable: false,
        },
        {
          field: "url",
          headerName: t("url"),
          flex: 1,
          minWidth: 80,
          sortable: false,
          renderCell: (params: any) => (
            <div>
              {params?.row.url.includes("://")
                 ? <a href={params?.row.url} target="_blank" rel="noreferrer">open</a>
                 : params?.row.url
              }
            </div>
          )
        },
        {
          field: "risk_type",
          headerName: t("riskType"),
          flex: 1,
          minWidth: 80,
          sortable: false,
          renderCell: (params: any) => (
            <div>
              {params?.row.risk_type_FR || t(`eventRiskType.${params?.row.risk_type}`)}
            </div>
          )
        }
    ];

    const failedEventColums = columns.concat([
        {
            field: "reason",
            headerName: t("eventEditor.upload.reason"),
            flex: 2,
            minWidth: 80,
            sortable: false,
            renderCell: (params: any) => (
                <div style={{textTransform: "capitalize"}}>
                  {t(`eventEditor.upload.${params?.row.reason}`)}
                </div>
            )
        },
    ])

    return (
        <Fragment>
            <SearchResultBlock title={t('upload')}>
                <form onSubmit={handleEventFileSubmit}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Box>
                            <label htmlFor="file-upload">{t('eventEditor.uploadEventFileLabel')}:</label>
                        </Box>
                        <Box>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                        >
                            {t('eventEditor.selectEventFileLabel')}
                            <VisuallyHiddenInput
                                type="file"
                                onChange={handleEventFileChange}
                                multiple
                            />
                            </Button>
                        </Box>
                        <Box>
                            <Button type="submit">{t('send')}</Button>
                        </Box>
                    </Stack>
                </form>
            </SearchResultBlock>
            { uploadFailedEvents.length > 0 &&
                <SearchResultBlock title={t('eventEditor.upload.linesWithError')} titleStyle={{backgroundColor: "red"}}>
                    <DataGridComponent
                        rows={uploadFailedEvents}
                        cols={failedEventColums}
                        textNorows={t("noEvent")}
                    />
                </SearchResultBlock>
            }
            <SearchResultBlock title={t('eventEditor.eventFromDatabase')}>
                <Stack alignItems="center">
                    <Pagination
                        count={Math.ceil(eventCount / itemsPerPage)}
                        onChange={handlePageChange}
                    />
                </Stack>
                <DataGridComponent
                    rows={events}
                    cols={columns}
                    textNorows={t("noEvent")}
                />
                <Stack alignItems="center">
                    <Pagination
                        count={Math.ceil(eventCount / itemsPerPage)}
                        onChange={handlePageChange}
                    />
                </Stack>
            </SearchResultBlock>
        </Fragment>
    );
}