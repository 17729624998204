import { Button, CircularProgress, styled } from "@mui/material";
import "./ButtonConfirm.css";

export interface ButtonConfirmProps {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  style?: any;
  loading?: boolean;
  size?: string;
  sx?: any
}

const ButtonStyled = styled(Button)({
  boxShadow: "none",
  backgroundColor: "#304770",
  borderColor: "#304770",
  color: "#fff",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: "#0062cc",
    borderColor: "#0062cc",
    boxShadow: "none",
  },
});

export default function ButtonConfirm({
  title,
  disabled = false,
  onClick,
  style,
  loading = false,
  size,
  sx
}: ButtonConfirmProps) {
  return (
    <div className="buttonConfirm" style={style}>
      <ButtonStyled
        disabled={disabled}
        variant="contained"
        onClick={onClick}
        style={size ? { width: size } : {}}
        sx={sx}
      >
        {loading ? <CircularProgress size={24} sx={{ color: "white" }}/> : title}
      </ButtonStyled>
    </div>
  );
}
